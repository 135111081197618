import React from 'react'
import Slider from './Slider';
import LatestRecepies from './LatestRecepies';
import Catagorywise from './Catagorywise';


const Home = () => {
  return (
    <>
      <Slider/>
      <LatestRecepies/>
      <Catagorywise/>
      
    </>
  )
}

export default Home
